import { AboutView } from 'pages/About/AboutView'
import { HomeView } from 'pages/Home/HomeView'
import { createContext, ReactNode, useContext, useState } from 'react'
import { EContent, ERoute } from 'ts/enum'
import BgHome from 'assets/images/pouchy_bg.jpg'
import BgAbout from 'assets/images/about_bg.png'
import BgTokenomics from 'assets/images/tokenomics_bg.jpeg'
import { TokenOmicsView } from 'pages/TokenOmics/TokenOmicsView'

export type TContentList = { view: JSX.Element; bg: string; id: ERoute }

type ContentContext = {
  currentContent: number
  setCurrentContent: (e: number) => void
  contentList: TContentList[]
}
const ContentContextDefaultValue = {
  currentContent: EContent.HOME,
  setCurrentContent: (e: EContent) => e,
  contentList: []
}
const context = createContext<ContentContext>(ContentContextDefaultValue)

export function useContentContext() {
  return useContext(context)
}

export function ContentProvider({ children }: { children: ReactNode | string | JSX.Element }) {
  const [currentContent, setCurrentContent] = useState(EContent.HOME)
  const contentList: TContentList[] = [
    { view: <HomeView />, bg: BgHome, id: ERoute.HOME },
    { view: <AboutView />, bg: BgAbout, id: ERoute.ABOUT },
    { view: <TokenOmicsView />, bg: BgTokenomics, id: ERoute.TOKENOMICS }
  ]

  const value = {
    currentContent,
    setCurrentContent,
    contentList
  }
  return <context.Provider value={value}>{children}</context.Provider>
}
