import React from 'react'

export const Footer = React.memo(() => {
  return (
    <>
      Pouchy is a fictitious utility pouch. It is not owned, related nor associated with the Brett
      token.
    </>
  )
})
