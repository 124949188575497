export const CopyIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_159_2)">
        <path
          d="M1.21577 2.85938H5.90288V1.03906H5.90516C5.90516 0.751953 6.04228 0.492188 6.26166 0.304688C6.47877 0.119141 6.78271 0.00195313 7.11864 0.00195313V0H16.1706H16.4494L16.6505 0.171875L23.8012 6.30664L24 6.71484V20.2305H23.9977C23.9977 20.5176 23.8629 20.7773 23.6435 20.9648H23.6412C23.4241 21.1523 23.1202 21.2676 22.7842 21.2676V21.2695H18.0971V22.959H18.0948C18.0948 23.2461 17.96 23.5059 17.7406 23.6934H17.7383C17.5189 23.8789 17.2173 23.9961 16.8814 23.9961V23.998H1.21577V23.9961C0.879832 23.9961 0.57589 23.8789 0.356504 23.6914C0.139402 23.5039 0.00228528 23.2461 0.00228528 22.959H0V3.90039H0.00228528C0.00228528 3.61328 0.139402 3.35352 0.358789 3.16602C0.57589 2.98047 0.879832 2.86328 1.21577 2.86328V2.85938ZM7.25805 2.85938H10.2678H10.5466L10.7477 3.03125L17.8983 9.16602L18.0971 9.57422V20.1113H22.6425V7.07813H17.0779C16.6711 7.07813 16.3032 6.93359 16.0358 6.69922C15.7684 6.4668 15.6016 6.14453 15.6016 5.79102V1.16016H7.25805V2.85938ZM15.629 0.580078H16.1706L23.3213 6.71484V7.05469H17.0779C16.2803 7.05469 15.6267 6.48438 15.6267 5.78906V0.580078H15.629ZM16.742 9.9375H11.1773C10.7705 9.9375 10.4026 9.79297 10.1352 9.55859C9.86783 9.32617 9.70101 9.00391 9.70101 8.65039V4.01953H5.90288H1.35746V22.8398H16.742V21.2695V20.6895V9.9375Z"
          fill="#FFD60A"
        />
      </g>
      <defs>
        <clipPath id="clip0_159_2">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}
