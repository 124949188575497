import React from 'react'
import styles from 'style/homeView.module.scss'
import BaseBounchy from 'assets/images/basedBounchy.png'

export const HomeView = React.memo(() => {
  return (
    <div
      className={`d-flex align-items-center justify-content-center flex-column h-100 ${styles.homeViewContainer}`}
    >
      <div className={styles.introducingText}>INTRODUCING</div>
      <img src={BaseBounchy} alt="" className={styles.baseBounchyImage} />
      <div className={styles.slogan}>
        A Based memecoin utility pouch of Brett, a crypto cultural icon and the mascot of BASE chain
      </div>
    </div>
  )
})
