import React from 'react'

export const EmailIcon = React.memo(() => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.951 4.31098C19.8679 3.8909 19.6805 3.49387 19.407 3.16008C19.3515 3.09 19.2934 3.02766 19.2299 2.96441C18.7434 2.47535 18.0667 2.19484 17.3732 2.19484H2.62672C1.92551 2.19484 1.26602 2.46828 0.769727 2.96469C0.706914 3.02734 0.648672 3.09031 0.591016 3.16262C0.31875 3.495 0.132148 3.89148 0.0510156 4.31215C0.0171094 4.47734 0 4.64851 0 4.82168V15.1786C0 15.5389 0.0732031 15.8898 0.21832 16.2233C0.343906 16.5195 0.534453 16.8004 0.76957 17.0354C0.828789 17.0943 0.887617 17.1487 0.95043 17.2017C1.42051 17.591 2.01574 17.8052 2.62672 17.8052H17.3732C17.9881 17.8052 18.5828 17.5902 19.0516 17.1974C19.1143 17.1467 19.1723 17.0934 19.2304 17.0354C19.4577 16.8083 19.6383 16.5475 19.7682 16.2598L19.7853 16.2188C19.9277 15.8916 20 15.5418 20 15.1786V4.82168C20 4.65078 19.9835 4.47848 19.951 4.31098ZM1.36027 3.87125C1.39742 3.81687 1.44484 3.76105 1.50535 3.70008C1.8057 3.39988 2.20402 3.23469 2.62668 3.23469H17.3732C17.7995 3.23469 18.198 3.40016 18.495 3.70066C18.5463 3.7525 18.5953 3.81004 18.638 3.86754L18.7508 4.0191L10.8797 10.8791C10.637 11.0918 10.3246 11.2089 9.99988 11.2089C9.67836 11.2089 9.36621 11.0921 9.12066 10.8794L1.25742 4.02105L1.36027 3.87125ZM1.04512 15.27C1.04086 15.2418 1.03988 15.2105 1.03988 15.1786V5.03613L7.14809 10.3647L1.10152 15.6366L1.04512 15.27ZM18.1601 16.5562C17.9237 16.6927 17.6514 16.765 17.3732 16.765H2.62672C2.34832 16.765 2.07617 16.6927 1.83992 16.5562L1.59273 16.413L7.84023 10.9685L8.52492 11.564C8.93563 11.9206 9.45937 12.1171 9.99996 12.1171C10.5425 12.1171 11.0671 11.9206 11.4776 11.564L12.162 10.9682L18.4073 16.4133L18.1601 16.5562ZM18.9598 15.1786C18.9598 15.21 18.9593 15.241 18.9553 15.2686L18.9012 15.6386L12.852 10.3675L18.9598 5.03871V15.1786Z"
        fill="white"
      />
    </svg>
  )
})
