import React from 'react'
import styles from 'style/tokenomicsView.module.scss'
import TokenOmicsLogo from 'assets/images/tokenomics.png'
import TokenOmicsGif from 'assets/images/tokenomicsGif.gif'
import { Button } from 'components'
import { useNotificationContext } from 'context/NotiContext'

export const TokenOmicsView = React.memo(() => {
  const { setIsShowNoti } = useNotificationContext()

  return (
    <div className={`d-flex flex-column gap-3 h-100 ${styles.tokenomicsView}`}>
      <div className="row ">
        <div className="col-12 col-lg-6">
          <img src={TokenOmicsLogo} alt="" className={styles.tokenOmicsLogo} />
        </div>
        <div className="col-12 col-lg-6">
          <div className={styles.contactAddressText}>Contract address:</div>
          <Button
            title={
              <div className={`${styles.addressValue} d-flex gap-2 align-items-center`}>
                <div className="truncate-1">0x3179bab60cfb2713f6ff70f0496b4cf1aa55b065</div>
              </div>
            }
            className={`${styles.copyButton}`}
            onClick={() => {
              setIsShowNoti(true)
              navigator.clipboard.writeText('0x3179bab60cfb2713f6ff70f0496b4cf1aa55b065')
            }}
          />
        </div>
      </div>
      <div className="row gy-5">
        <div className="col-12 col-lg-6">
          <div className="d-flex align-items-center justify-content-center flex-column h-100">
            <img src={TokenOmicsGif} alt="" className={styles.chart} />
            <div className="d-block d-lg-none">
              <div className="d-flex justify-content-center justify-content-lg-start gap-2">
                <Button
                  title={'CONTRACT RENOUNCED'}
                  className={styles.contactRenounced}
                  classNameText={`truncate-1 ${styles.contactRenouncedText}`}
                  disable
                />
                <Button
                  title={'LP LOCKED 365 DAYS'}
                  className={styles.locked}
                  classNameText={`truncate-1 ${styles.lockedText}`}
                  disable
                />
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="d-flex align-items-center h-100">
            <div className={`${styles.tokenomicsContent}`}>
              <div className={styles.contractLabel}>BLOCKCHAIN: BASE</div>
              <div className={styles.contractLabel}>SUPPLY: 42,690,000,000</div>
              <div className={styles.contractLabel}>85% LP</div>
              <div className={styles.contractLabel}>10% TREASURY</div>
              <div className={styles.contractLabel}>5% TEAM</div>
            </div>
          </div>
        </div>
      </div>
      <div className="d-none d-lg-block">
        <div className="row  pb-5 pb-lg-0">
          <div className="col-12 col-lg-8">
            <div className="d-flex gap-2 flex-wrap flex-lg-nowrap">
              <Button
                title={'CONTRACT RENOUNCED'}
                className={styles.contactRenounced}
                classNameText={`truncate-1 ${styles.contactRenouncedText}`}
                disable
              />
              <Button
                title={'LP LOCKED 365 DAYS'}
                className={styles.locked}
                classNameText={`truncate-1 ${styles.lockedText}`}
                disable
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})
