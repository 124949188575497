import { CopyIcon } from 'assets/icons/CopyIcon'
import { Button } from 'components/Button/Button'
import { useNotificationContext } from 'context/NotiContext'
import { motion } from 'framer-motion'
import styles from 'style/notification.module.scss'

export const Notification = () => {
  const { isShowNoti } = useNotificationContext()

  const y = window.innerWidth < 768 ? -25 : 15

  if (!isShowNoti) return null

  return (
    <motion.div
      initial={{ y: -150 }}
      animate={{ y: y }}
      exit={{ y: -150 }}
      transition={{ duration: 0.2, delay: 0.2 }}
      className={`position-fixed ${styles.notiWrapper}`}
      style={{ zIndex: 9999 }}
    >
      <div className={styles.noti}>
        <div className="d-flex align-items-center justify-content-center gap-2 flex-wrap">
          <CopyIcon />
          <div className="fw-bold">Contract Address Copied</div>
          <div className="opacity-50">Double check when pasted</div>
          <Button
            className={styles.notiButton}
            title="Block Explorer"
            onClick={() => {
              window.open('https://basescan.org/address/0x3179bab60cfb2713f6ff70f0496b4cf1aa55b065')
            }}
          />
        </div>

        <div className={styles.blur} />
      </div>
    </motion.div>
  )
}
