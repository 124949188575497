export enum ERoute {
  HOME = '/',
  ABOUT = '/about',
  TOKENOMICS = '/tokenomics',
  NOT_FOUND = '*'
}

export enum EContent {
  HOME = 0,
  ABOUT = 1,
  TOKENOMICS = 2
}
