import { Header } from './Header'
import styles from 'style/layout.module.scss'
import { Footer } from './Footer'
import React, { useEffect, useRef, useState } from 'react'
import { AnimatePresence, motion, useAnimation } from 'framer-motion'
import { TContentList, useContentContext } from 'context/Content'
import { DotPagination } from 'components/DotPagination/DotPagination'
import { EContent } from 'ts/enum'

export const Layout = React.memo(() => {
  const controls = useAnimation()
  const { currentContent, setCurrentContent, contentList } = useContentContext()
  const scrollRef = useRef(null)

  // useEffect(() => {
  //   const handleScroll = () => {
  //     if (!scrollRef.current) return

  //     const { scrollTop, scrollHeight, clientHeight } = scrollRef.current
  //     const scrollPercentage = scrollTop / (scrollHeight - clientHeight)
  //     const lowerBound = 0.3
  //     const upperBound = 0.6

  //     let newOpacity = 1

  //     if (currentContent === 0) {
  //       if (scrollPercentage > upperBound) {
  //         newOpacity = (1 - scrollPercentage) / (1 - upperBound)
  //       }
  //     } else if (currentContent === contentList.length - 1) {
  //       if (scrollPercentage < lowerBound) {
  //         newOpacity = scrollPercentage / lowerBound
  //       }
  //     } else {
  //       if (scrollPercentage < lowerBound) {
  //         newOpacity = scrollPercentage / lowerBound
  //       } else if (scrollPercentage > upperBound) {
  //         newOpacity = (1 - scrollPercentage) / (1 - upperBound)
  //       }
  //     }

  //     controls.start({ opacity: newOpacity })

  //     const isBottom = scrollTop + clientHeight >= scrollHeight
  //     const isTop = scrollTop <= 0

  //     if (isBottom && currentContent < contentList.length - 1) {
  //       controls.start({ opacity: 0 }).then(() => {
  //         setCurrentContent(currentContent + 1)
  //         controls.start({ opacity: 1 })
  //         // @ts-ignore
  //         scrollRef.current.scrollTop = 0
  //       })
  //     } else if (isTop && currentContent > 0) {
  //       controls.start({ opacity: 0 }).then(() => {
  //         setCurrentContent(currentContent - 1)
  //         controls.start({ opacity: 1 })
  //         // @ts-ignore
  //         scrollRef.current.scrollTop = scrollRef.current.scrollHeight
  //       })
  //     }
  //   }

  //   const scrollElement = scrollRef.current
  //   if (scrollElement) {
  //     // @ts-ignore
  //     scrollElement.addEventListener('scroll', handleScroll)
  //   }

  //   return () => {
  //     if (scrollElement) {
  //       // @ts-ignore
  //       scrollElement.removeEventListener('scroll', handleScroll)
  //     }
  //   }
  // }, [controls, currentContent, contentList.length])

  return (
    <div className="position-relative h-100" style={{ overflowY: 'auto' }}>
      <div className={`position-absolute ${styles.background}`}>
        <AnimatePresence>
          <motion.div
            id={contentList[currentContent].id}
            key={currentContent}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0, transition: { duration: 0.2 } }}
            transition={{ duration: 1 }}
            className={`position-absolute ${styles.background}`}
            style={{
              backgroundImage: `url(${contentList[currentContent].bg})`,
              zIndex: 1
            }}
          />
        </AnimatePresence>
      </div>
      <div className={`d-flex flex-column ${styles.layoutContainer}`}>
        <Header />
        <div className="row" style={{ flex: 1 }}>
          <div className="d-none d-md-block col-md-1 col-xl-2" />
          <div className="col-12 col-md-10 col-xl-8 h-100" style={{ flex: 1 }}>
            <div className={styles.windowPlatter}>
              <motion.div
                className={`${styles.outletContent}`}
                initial={{ opacity: 1 }}
                // animate={controls}
                ref={scrollRef}
                key={currentContent}
              >
                {contentList[currentContent].view}
              </motion.div>
              {currentContent !== EContent.HOME && (
                <DotPagination className={`position-absolute d-none d-lg-flex `} />
              )}
              <div className={styles.blur} />
            </div>
          </div>
          <div className="d-none d-md-block col-md-1 col-xxl-2" />
        </div>

        <div className="row position-relative">
          <div className="col-md-1 col-xl-2" />
          <div className="col-md-10 col-xl-8">
            <div className={styles.footer}>
              <Footer />
            </div>
          </div>
          <div className="col-md-1 col-xxl-2" />
        </div>
      </div>
    </div>
  )
})
