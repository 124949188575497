import React from 'react'

type Props = {}

export const SushiSwapIcon = (props: Props) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_160_4" maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
        <path d="M24 0H0V24H24V0Z" fill="white" />
      </mask>
      <g mask="url(#mask0_160_4)">
        <path
          d="M5.00844 2.31604L23.5947 15.0253L18.9916 21.7998L0.405308 9.00362L5.00844 2.31604Z"
          fill="url(#paint0_linear_160_4)"
        />
        <path
          d="M23.5947 14.9964C22.0024 17.2835 16.5887 16.386 11.4934 12.7961C6.31122 9.32208 3.50302 4.60314 5.00844 2.31604C6.60072 1.43464e-06 12.0145 0.897468 17.1098 4.51629C22.2919 7.9035 25.1001 12.7093 23.5947 14.9964Z"
          fill="url(#paint1_linear_160_4)"
        />
        <path
          d="M18.9916 21.7129C17.3993 24 11.9855 23.1025 6.89023 19.5127C1.79493 15.9228 -1.10012 11.3197 0.492159 8.91677C2.08444 6.62968 7.49819 7.52714 12.5935 11.117C17.6888 14.7069 20.497 19.3969 18.9916 21.7129Z"
          fill="url(#paint2_linear_160_4)"
        />
        <path
          d="M23.5947 14.9964L18.9916 21.7998C17.3993 24.0869 11.9855 23.1025 6.89023 19.5995C5.87696 18.9047 4.97949 18.2099 4.08203 17.3993C4.77684 17.3124 5.67431 16.9071 6.57177 15.8938C8.16405 14.1858 8.97467 13.7805 9.66948 13.8963C10.3643 13.8963 11.1749 14.5911 12.4777 16.2992C13.7805 18.0072 15.5754 18.4994 16.6755 17.6019C16.7624 17.5151 16.8782 17.5151 16.965 17.3993C17.8625 16.7045 18.152 16.386 19.8601 13.2014C20.2654 12.3908 21.655 11.0881 23.5657 11.696C24.1158 12.9988 24.1158 14.0989 23.5947 14.9964Z"
          fill="#0E0F23"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M22.8999 14.62C21.5103 16.6176 16.5887 15.6333 11.8987 12.3329C7.09288 9.00362 4.31363 4.80579 5.70326 2.8082C7.09288 0.810615 12.0145 1.79493 16.7045 5.0953C21.3945 8.39566 24.2027 12.6224 22.8999 14.62ZM18.4994 11.6092C17.8046 12.6224 15.4017 12.1013 12.9988 10.509C10.7117 8.91677 9.29312 6.80338 10.0169 5.81906C10.7407 4.83474 13.1146 5.3269 15.5175 6.91918C17.8046 8.51146 19.1942 10.5959 18.4994 11.6092Z"
          fill="white"
        />
        <path
          d="M4.60314 4.60314C4.60314 4.51628 4.51629 4.40048 4.40048 4.51628C4.31363 4.60314 4.19783 4.60314 4.19783 4.71894C4.28468 5.00844 4.40048 5.2111 4.40048 5.41375C4.40048 5.5006 4.48733 5.6164 4.60314 5.5006C4.68999 5.5006 4.80579 5.41375 4.68999 5.29795C4.71894 5.12425 4.71894 4.92159 4.60314 4.60314Z"
          fill="white"
        />
        <path
          d="M5.0953 6.22437C5.0953 6.13751 5.00844 6.02171 4.89264 6.13752C4.80579 6.22437 4.80579 6.22437 4.80579 6.34017C5.90591 8.82992 8.193 11.5513 11.2039 13.5489C11.2907 13.6357 11.4065 13.5489 11.4934 13.5489C11.5802 13.462 11.4934 13.3462 11.4934 13.2593C8.39566 11.2039 6.19542 8.59831 5.0953 6.22437Z"
          fill="white"
        />
        <path
          d="M17.1966 16.0097C17.1098 16.0097 16.994 16.0097 16.994 16.0965C16.994 16.1834 16.994 16.2992 17.0808 16.2992C17.3703 16.386 17.7756 16.5018 18.0941 16.5887C18.1809 16.5887 18.2967 16.5887 18.2967 16.5018C18.2967 16.415 18.2967 16.2992 18.2099 16.2992C17.8914 16.2123 17.5151 16.0965 17.1966 16.0097Z"
          fill="white"
        />
        <path
          d="M18.9916 16.415C18.9047 16.415 18.7889 16.5018 18.7889 16.6176C18.7889 16.7334 18.8758 16.8203 18.9916 16.8203C19.8022 16.9071 20.6996 17.0229 21.3945 16.9071C21.4813 16.9071 21.5971 16.8203 21.5971 16.7045C21.5971 16.5887 21.5103 16.5018 21.3945 16.5018C20.6128 16.6176 19.8022 16.5018 18.9916 16.415Z"
          fill="white"
        />
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_160_4"
          x1="8.65369"
          y1="0.503856"
          x2="12.3418"
          y2="13.1684"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#03B8FF" />
          <stop offset="1" stopColor="#FA52A0" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_160_4"
          x1="11.6754"
          y1="-0.376211"
          x2="15.3636"
          y2="12.2883"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#03B8FF" />
          <stop offset="1" stopColor="#FA52A0" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_160_4"
          x1="5.66762"
          y1="1.37336"
          x2="9.35583"
          y2="14.038"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#03B8FF" />
          <stop offset="1" stopColor="#FA52A0" />
        </linearGradient>
      </defs>
    </svg>
  )
}
