import React, { ReactNode } from 'react'
import styles from 'style/buttonIcon.module.scss'

type ButtonProps = { icon: ReactNode; className?: string; onClick?: () => void; disable?: boolean }

export const ButtonIcon = (props: ButtonProps) => {
  const { icon, className, onClick, disable = false } = props
  return (
    <button
      onClick={() => {
        onClick && onClick()
      }}
      className={`${styles.buttonIcon} d-flex align-items-center justify-content-center ${
        className ? className : ''
      }`}
      disabled={disable}
    >
      <div className={styles.icon}>{icon}</div>
      <div className={styles.blur} />
    </button>
  )
}
