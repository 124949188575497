import { createContext, ReactNode, useContext, useEffect, useState } from 'react'
type NotificationContext = {
  isShowNoti: boolean
  setIsShowNoti: (e: boolean) => void
}
const NotificationContextDefaultValue = {
  isShowNoti: false,
  setIsShowNoti: (e: boolean) => e
}
const context = createContext<NotificationContext>(NotificationContextDefaultValue)

export function useNotificationContext() {
  return useContext(context)
}

export function NotificationProvider({ children }: { children: ReactNode | string | JSX.Element }) {
  const [isShowNoti, setIsShowNoti] = useState<boolean>(false)

  useEffect(() => {
    if (isShowNoti) {
      setTimeout(() => {
        setIsShowNoti(false)
      }, 4000)
    }
  }, [isShowNoti])

  const value = {
    isShowNoti,
    setIsShowNoti
  }
  return <context.Provider value={value}>{children}</context.Provider>
}
