import React from 'react'
import styles from 'style/about.module.scss'
import WhoPounchy from 'assets/images/whoPounchy.png'
import PounchyGif from 'assets/images/brettwithpouchy.gif'
import Logo from 'assets/images/logo.png'

export const AboutView = () => {
  return (
    <div className={`d-flex flex-column gap-3 ${styles.aboutView}`}>
      <div>
        <img src={WhoPounchy} alt="" className={styles.whoPounchy} />
      </div>
      <div className="row gy-2" style={{ flex: 1 }}>
        <div className="col-12 col-lg-4">
          <div className="position-relative d-flex justify-content-center">
            <img src={PounchyGif} alt="" className={styles.pounchyGif} />
          </div>
        </div>
        <div className="col-12 col-lg-8">
          <div className={styles.aboutViewRight}>
            <img src={Logo} alt="" className={styles.pounchyLogo} />
            <div className={styles.pounchyForEveryone}>IS FOR EVERYONE</div>
            <div className={styles.aboutContent}>
              {`Meet Pouchy, the utility pouch extraordinaire! It is an indispensable sidekick for
              Brett, always ready with quick-fix solutions. We all have our own Pouchy — a guardian
              angel in disguise, a whimsical wonder bringing a dash of serendipity to everyday
              adventures. Pouchy’s got you covered, proving that sometimes, the best heroes come
              with zippers and pockets!`}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
