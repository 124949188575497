import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { NotFoundView } from 'pages/NotFound/NotFoundView'
import { ERoute } from 'ts/enum'
import { HomeView } from 'pages/Home/HomeView'
import { Layout } from 'layout'
import { useUpdateViewHeight } from 'hooks/useUpdateViewHeight'
// import { AboutView } from 'pages/About/AboutView'
import { ContentProvider } from 'context/Content'
// import { TokenOmicsView } from 'pages/TokenOmics/TokenOmicsView'
import { NotificationProvider } from 'context/NotiContext'

function App() {
  useUpdateViewHeight()
  return (
    <ContentProvider>
      <NotificationProvider>
        <BrowserRouter>
          <Routes>
            <Route element={<Layout />}>
              <Route path={ERoute.HOME} element={<HomeView />} />
              {/* <Route path={ERoute.ABOUT} element={<AboutView />} />
              <Route path={ERoute.TOKENOMICS} element={<TokenOmicsView />} /> */}
              <Route path={ERoute.NOT_FOUND} element={<NotFoundView />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </NotificationProvider>
    </ContentProvider>
  )
}

export default App
