import React, { CSSProperties, ReactNode } from 'react'
import styles from 'style/button.module.scss'

type ButtonProps = {
  title: string | ReactNode
  className?: string
  onClick?: () => void
  style?: CSSProperties
  styleBlur?: CSSProperties
  classNameText?: string
  disable?: boolean
}

export const Button = (props: ButtonProps) => {
  const { title, className, onClick, style, styleBlur, classNameText, disable = false } = props
  return (
    <button
      className={`${styles.button} ${className ? className : ''}`}
      onClick={() => {
        onClick && onClick()
      }}
      disabled={disable}
      style={style ? style : {}}
    >
      <div className={`${classNameText ? classNameText : ''} ${styles.text}`}>{title}</div>
      <div className={styles.blur} style={styleBlur ? styleBlur : {}} />
    </button>
  )
}
