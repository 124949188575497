import { useContentContext } from 'context/Content'
import { EContent } from 'ts/enum'

type DotPaginationProps = {
  className: string
}

const routerMap = [{ url: EContent.ABOUT }, { url: EContent.TOKENOMICS }]

export const DotPagination = (props: DotPaginationProps) => {
  const { className } = props
  const { currentContent, setCurrentContent } = useContentContext()

  return (
    <div className={`d-flex align-items-center dotContainer gap-2 ${className}`}>
      {routerMap.map((e) => {
        return (
          <div
            key={e.url}
            className={`dotWrapper  ${currentContent === e.url ? '--active' : 'cursor-pointer'} `}
            onClick={() => {
              setCurrentContent(e.url)
            }}
          >
            <div className={`dot ${currentContent === e.url ? '--active' : ''} `} />
          </div>
        )
      })}
    </div>
  )
}
