import React from 'react'
import { Button } from 'components'
import { ButtonIcon } from 'components/ButtonIcon/ButtonIcon'
import logo from 'assets/images/logo.png'
import styles from 'style/header.module.scss'
import { XIcon } from 'assets/icons/XIcon'
import { EmailIcon } from 'assets/icons/EmailIcon'
import { TelegramIcon } from 'assets/icons/TelegramIcon'
import EthereumImg from 'assets/images/Ethereum.png'
import { useContentContext } from 'context/Content'
import { Notification } from 'components/Notification/Notification'
import { EContent } from 'ts/enum'
import { SushiSwapIcon } from 'assets/icons/SushiSwapIcon'

export const Header = React.memo(() => {
  const { setCurrentContent } = useContentContext()

  const email = 'brett@basedpouchy.com'
  const subject = 'gm pouchy'
  const body = ''
  const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(
    subject
  )}&body=${encodeURIComponent(body)}`
  const xLink = 'https://x.com/basedpouchy'
  const telegramLink = 'https://t.me/basedpouchy'
  const swapLink =
    'https://www.sushi.com/swap?chainId=8453&token0=NATIVE&token1=0x3179bAB60cfB2713F6ff70F0496B4cf1AA55B065'

  const handleEmailButtonClick = () => {
    window.location.href = `mailto:${email}?subject=${encodeURIComponent(
      subject
    )}&body=${encodeURIComponent(body)}`
  }

  const handleTelegramButtonClick = () => {
    window.open(telegramLink, '_blank')
  }

  const handleXButtonClick = () => {
    window.open(xLink, '_blank')
  }

  const handleSwapOnSuShiButtonClick = () => {
    window.open(swapLink, '_blank')
  }

  return (
    <div className={styles.header}>
      <div className="row align-items-center gy-2 position-relative">
        <Notification />
        <div className="d-flex align-items-center justify-content-center col-12 col-md-2 h-100">
          <div
            onClick={() => {
              setCurrentContent(EContent.HOME)
            }}
            className="cursor-pointer"
            style={{ width: 'fit-content' }}
          >
            <img src={logo} alt="" className={styles.logo} loading="lazy" />
          </div>
        </div>
        <div className="col-12 col-md-8 p-0">
          <div className="d-flex justify-content-center justify-content-lg-between flex-wrap gap-2">
            <div className="d-flex gap-2 align-items-center flex-wrap">
              <Button
                title="ABOUT"
                onClick={() => {
                  setCurrentContent(EContent.ABOUT)
                }}
                style={{ width: 114, textAlign: 'center' }}
              />
              <Button
                title="TOKENOMICS"
                onClick={() => {
                  setCurrentContent(EContent.TOKENOMICS)
                }}
                style={{ width: 142, textAlign: 'center' }}
              />
            </div>
            <div className="d-flex align-items-center gap-2 flex-wrap">
              <a href={mailtoLink}>
                <ButtonIcon icon={<EmailIcon />} onClick={handleEmailButtonClick} />
              </a>

              <a href={xLink}>
                <ButtonIcon icon={<XIcon />} onClick={handleXButtonClick} />
              </a>

              <a href={telegramLink}>
                <ButtonIcon icon={<TelegramIcon />} onClick={handleTelegramButtonClick} />
              </a>

              <a href={swapLink} className={`d-none d-lg-block`}>
                <Button
                  style={{ borderRadius: 28 }}
                  styleBlur={{ borderRadius: 28 }}
                  onClick={handleSwapOnSuShiButtonClick}
                  className={`${styles.swapOnSushiButton} d-none d-lg-block`}
                  title={
                    <div className={`${styles.swapOnSushiTitle} d-flex gap-2 align-items-center`}>
                      <img src={EthereumImg} className={styles.ethereumIcon} />
                      <div className="truncate-1 d-none d-md-block">Swap on Sushi</div>
                    </div>
                  }
                />
              </a>

              <ButtonIcon
                icon={<SushiSwapIcon />}
                className="d-block d-lg-none"
                onClick={handleSwapOnSuShiButtonClick}
              />
            </div>
          </div>
        </div>
        <div className="d-none d-md-block col-12 col-md-2" />
      </div>
    </div>
  )
})
